import graphql from 'graphql-tag';

export const GET_ACTIVITY_EVENTS = graphql`
  query ActivityEvents($pageArgs: PaginationArgs, $filter: ActivityEventFilterOptions, $sort: SortOrder) {
    activityEvents(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      totalItems
      items {
        id
        type
        originalData
        timestamp
        playPoints
        totalPoints
        ageCategoryCode
        location {
          organisationId
          organisationName
          locationId
          locationName
          sectionId
          sectionName
          districtId
          districtName
        }
        adminUser {
          firstName
          lastName
        }
        ballColour
        playPoints
        singlesWinPoints
        doublesWinPoints
        totalPoints
        totalWinPoints
        person {
          birthDate
          username
          firstName
          lastName
          sectionName
          districtName
          age
        }
      }
    }
  }
`;

export const GET_PERSON_BY_EXTERNAL_ID = graphql`
  query GetPersonByExternalId($externalId: String!) {
    personByExternalId(externalId: $externalId) {
      id: externalId
      personId
      email
      externalId
      status
      tennisId
      extensions {
        description
        name
        value
      }
      section {
        code
        name
      }
      district {
        code
        name
      }
      standardFamilyName
      standardGivenName
      createdAt
      sex
      birthDate
      age
      addresses {
        city
        state
        countryCode
      }
      state
      worldTennisNumbers {
        confidence
        gameZoneLower
        type
        tennisNumber
        source
        ratingDate
        gameZoneUpper
        isRanked
      }
    }
  }
`;

export const GET_PERSONS_BY_EXTERNAL_ID = graphql`
  query GetPersonsByExternalId($externalIds: [String!]!) {
    personsByExternalId(externalIds: $externalIds) {
      externalId
      standardFamilyName
      standardGivenName
    }
  }
`;
export const GET_PLAYER_PROFILE = graphql`
  query SingleProfile($id: PersonIDInput!) {
    activityProfile(id: $id) {
      id
      points {
        playPoints
        singlesWinPoints
        doublesWinPoints
        totalWinPoints
        totalPoints
        ballColour
        complete
        timestamp
      }
      ballColour
      coachAssignedLevel {
        ballColour
        level
        coachId
      }
      progression {
        daysToAdvance
        pointsToAdvance
        ballColour
      }
      counts {
        type
        ballColour
        count
        totalWinPoints
        totalPoints
        playPoints
        singlesWinPoints
        doublesWinPoints
      }
      person {
        id
        birthDate
        username
        firstName
        lastName
        sectionName
        districtName
        gender
        state
        zipcode
        city
        wheelchairPlayer
        age
      }
    }
  }
`;

export const EVALUATE_PROFILE = graphql`
  mutation EvaluateProfile($filter: ActivityTrackerEvaluationFilterOptions) {
    activityProfileEvaluation(filter: $filter)
  }
`;

export const GET_PLAYERS = graphql`
  query ActivityProfiles($pageArgs: PaginationArgs, $filter: ActivityTrackerProfileFilterOptions, $sort: SortOrder) {
    activityProfiles(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      items {
        id
        points {
          playPoints
          ballColour
          complete
          timestamp
        }
        ballColour
        progression {
          daysToAdvance
          pointsToAdvance
          ballColour
        }
        counts {
          type
          ballColour
          count
        }
        person {
          id
          birthDate
          username
          firstName
          lastName
          sectionName
          districtName
          gender
          state
          zipcode
          city
          wheelchairPlayer
          age
        }
      }
      totalItems
    }
  }
`;

export const GET_PLAYERS_BY_UAID = graphql`
  query GetPlayersByUAID($ids: [ID!]!) {
    findUSTAMembersByID(ids: $ids) {
      id
      birthDate
      username
      firstName
      lastName
      sectionName
      districtName
      gender
      state
      zipcode
      city
      wheelchairPlayer
      age
    }
  }
`;

export const SEARCH_PLAYERS = graphql`
  query SearchPeople($pageArgs: PaginationArgs, $filter: PersonFilterOptionsInput, $sort: SortOrder) {
    searchPeople(pageArgs: $pageArgs, filter: $filter, sort: $sort) {
      results {
        id: externalId
        personId
        externalId
        standardFamilyName
        standardGivenName
        age
        birthDate
        district {
          name
        }
        section {
          name
        }
        addresses {
          city
        }
        state
        sex
        groups {
          groupId
          groupName
        }
      }
      total
    }
  }
`;

export const SEARCH_GROUP = graphql`
  query findGroup($pageArgs: PaginationArgs, $filter: GroupFilterOptionsInput, $sort: SortOrder) {
    findGroup(pageArgs: $pageArgs, filter: $filter, sort: $sort) {
      results {
        groupId
        groupName
      }
      total
    }
  }
`;

export const ADD_ACTIVITY_POINTS = graphql`
  mutation AddActivityPoints($id: PersonIDInput!, $input: ActivityManualAdjustmentInput!) {
    addActivityManualAdjustment(id: $id, input: $input) {
      id
      timestamp
      ballColour
      playPoints
      person {
        birthDate
        username
        firstName
        lastName
        sectionName
        districtName
      }
    }
  }
`;

//SUSPENSIONS
export const LIST_SUSPENSIONS = graphql`
  query ListSuspensions {
    listSuspensions {
      suspensionId
      externalId
      startDate
      endDate
      comment
    }
  }
`;

export const LIST_SUSPENSIONS_FOR_PLAYER = graphql`
  query ListSuspensionsForPlayer($externalId: String!) {
    listSuspensionsForPlayer(externalId: $externalId) {
      suspensionId
      person {
        personId
        standardFamilyName
        standardGivenName
        birthDate
        district {
          name
        }
        section {
          name
        }
      }
      startDate
      endDate
      comment
    }
  }
`;

export const CREATE_SUSPENSION = graphql`
  mutation CreateSuspension($input: SuspensionCreateInput!) {
    createSuspension(suspensionCreateInput: $input) {
      suspensionId
      externalId
      startDate
      endDate
      comment
      person {
        standardFamilyName
        standardGivenName
        birthDate
        district {
          name
        }
        section {
          name
        }
      }
    }
  }
`;

export const UPDATE_SUSPENSION = graphql`
  mutation UpdateSuspension($input: SuspensionUpdateInput!, $suspensionId: String!) {
    updateSuspension(suspensionUpdateInput: $input, suspensionId: $suspensionId) {
      suspensionId
      person {
        personId
        standardFamilyName
        standardGivenName
        birthDate
        district {
          name
        }
        section {
          name
        }
      }
      startDate
      endDate
      comment
    }
  }
`;

export const DELETE_SUSPENSION = graphql`
  mutation DeleteSuspension($suspensionId: String!) {
    deleteSuspension(suspensionId: $suspensionId) {
      suspensionId
      externalId
    }
  }
`;

//SUSPENSION CODES
export const GET_SUSPENSION_CODES = graphql`
  query ListSuspensionCodes {
    listSuspensionCodes {
      code
      shortDescription
      longDescription
      points
    }
  }
`;

export const GET_SUSPENSION_CODE = graphql`
  query GetSuspensionCode($code: String!) {
    getSuspensionCode(code: $code) {
      code
      shortDescription
      longDescription
      points
    }
  }
`;

export const UPDATE_SUSPENSION_CODE = graphql`
  mutation UpdateSuspensionCode($code: String!, $input: SuspensionCodeUpdateInput!) {
    updateSuspensionCode(
      code: $code # the suspension code to update
      suspensionCodeUpdateInput: $input
    ) {
      code
      shortDescription
      longDescription
      points
    }
  }
`;

export const ADD_SUSPENSION_CODE = graphql`
  mutation CreateSuspensionCode($input: SuspensionCodeCreateInput!) {
    createSuspensionCode(suspensionCodeCreateInput: $input) {
      code
      shortDescription
      longDescription
      points
    }
  }
`;

//VIOLATIONS
export const LIST_PLAYER_VIOLATIONS = graphql`
  query ListViolationsForPlayer($externalId: String!, $expired: Boolean, $suspensionId: String) {
    listViolationsForPlayer(externalId: $externalId, expired: $expired, suspensionId: $suspensionId) {
      violationId
      externalId
      suspensionCode {
        code
        points
      }
      expiryDate
      createdAt
      violationDate
      comment
      tournamentId
      tournamentName
      tournamentStartDate
      tournamentEndDate
      tournamentDirectorPhone
      tournamentDirectorComment
      isTournamentDesk
      refereeFirstName
      refereeLastName
      refereeexternalId
      refereePhone
      eventName
      organizationName
      roundName
      matchUpPlayers
      matchUpResult
      reporterFirstName
      reporterLastName
    }
  }
`;

export const GET_PLAYER_SUSPENSION_STATUS = graphql`
  query GetPlayerSuspensionStatus($externalId: String!) {
    getPlayerSuspensionStatus(externalId: $externalId) {
      points
      status
      suspension {
        suspensionId
        comment
        startDate
        endDate
      }
    }
  }
`;

export const LIST_ALL_PLAYER_VIOLATIONS = graphql`
  query ListAllPlayerViolations($pageArgs: PaginationArgs, $filterOptions: ListPlayersFilter, $sort: SortOrder) {
    listAllPlayerViolations(pageArgs: $pageArgs, filterOptions: $filterOptions, sort: $sort) {
      totalPlayers
      players {
        points
        status
        externalId
        person {
          personId
          standardGivenName
          standardFamilyName
          birthDate
          district {
            name
          }
          section {
            name
          }
          category
        }
      }
    }
  }
`;

//ACTIONS
export const LIST_PLAYER_ACTIONS = graphql`
  query ListPlayerActions($externalId: String!, $pageArgs: PaginationArgs) {
    listPlayerActions(externalId: $externalId, pageArgs: $pageArgs) {
      totalActions
      actions {
        externalId
        type
        createdAt
        user {
          email
          firstName
          lastName
        }
        objectDeleted
        originalObject {
          __typename
          ... on Suspension {
            suspensionId
            comment
            # any other fields needed
          }
          ... on Violation {
            violationId
            comment
            # any other fields needed
          }
          ... on SuspensionEmail {
            suspensionEmailId
            comment
            # any other fields needed
          }
          ... on SuspensionCode {
            suspensionCodeId
            # any other fields needed
          }
        }
      }
      totalActions
    }
  }
`;

export const UPDATE_SUSPENSION_EMAIL = graphql`
  mutation UpdateSuspensionEmail($suspensionEmailId: String!, $input: SuspensionEmailUpdateInput!) {
    updateSuspensionEmail(suspensionEmailUpdateInput: $input, suspensionEmailId: $suspensionEmailId) {
      suspensionEmailId
      comment
      type
      externalId
    }
  }
`;

export const CREATE_SUSPENSION_EMAIL = graphql`
  mutation CreateSuspensionEmail($input: SuspensionEmailCreateInput!) {
    createSuspensionEmail(suspensionCreateInput: $input) {
      suspensionEmailId
      comment
      type
      externalId
    }
  }
`;

export const GET_SUSPENSION = graphql`
  query GetPlayerSuspension($id: String!) {
    getSuspension(suspensionId: $id) {
      suspensionId
      comment
      externalId
      person {
        personId
        standardFamilyName
        standardGivenName
        birthDate
        district {
          name
        }
        section {
          name
        }
      }
      startDate
      endDate
    }
  }
`;
