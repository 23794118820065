import React from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { ADD_SUSPENSION_CODE } from 'src/components/players/players-queries';
import SuspensionCodeForm from 'src/components/suspension-code-form/suspension-code-form';

const AddSuspensionCode = () => {
  const { t } = useTranslation();

  const initialValues = {
    codeName: '',
    type: t('point'),
    points: '',
    duration: '',
    shortDescription: '',
    longDescription: '',
    durationFormat: '',
  };

  const [addSuspensionCode, { loading, error, data }] = useMutation(ADD_SUSPENSION_CODE, {
    client: meshGatewayClient,
  });

  const handleCancel = () => {
    navigate('/suspension/codes');
  };

  const handleSubmit = async (values) => {
    try {
      const res = await addSuspensionCode({
        variables: {
          input: {
            code: values?.codeName,
            longDescription: values?.longDescription,
            shortDescription: values?.shortDescription,
            points: parseInt(values?.points),
          },
        },
      });
      navigate('/suspension/codes');
    } catch (error) {}
  };

  return (
    <SuspensionCodeForm
      title={t('add code')}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      initialValues={initialValues}
      loading={loading}
      error={error}
    />
  );
};

export default AddSuspensionCode;
